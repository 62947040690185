$color-primary: #a5d269;
$text-color: rgb(139, 144, 145);
$title-text-color: rgb(74, 76, 76);


$heading-font-size: 22px;
$heading-font-weight: 700;
$text-font-size: 17px;
$text-font-weight: 400;
$title-font-size: 70px;
$title-font-weight: 700;

.d-flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.get-hover:hover {
  cursor: pointer;
}

.home-page-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.mb-30 {
  margin-bottom: 30px !important;
}
