.about-us-page-container {
  .headings-container {
    height: 250px;
    background-color: #f4f5f7;
    flex-direction: column;

    .primary-heading {
      color: rgb(74, 76, 76);
      font-weight: $title-font-weight;
      font-size: 50px;
      margin-bottom: 10px;
    }

    .secondary-heading {
      color: rgb(180, 181, 187);
      font-size: 15px;
    }
  }

  .content-main-container{
    padding: 50px 135px;

    .heading {
      color: rgb(165, 210, 105);
      font-size: 22px;
      font-style: italic;
      font-weight: $title-font-weight;
    }

    .content {
      color: rgb(139, 144, 145);
      font-size: 17px;
      line-height: 30px;
    }

    .horizontal-line {
      border-top: 1px solid   rgba(139, 144, 145, 0.2);
    }
  }
}

@media screen and (max-width: 768px) {
  .about-us-page-container {
    .headings-container {
      .primary-heading {
        font-size: 30px;
      }
    }
    .content-main-container{
      padding: 50px 20px;
    }
  }
}
