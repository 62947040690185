.contact-us-page-container,
.yoga-page-container {
  .headings-container {
    height: 250px;
    background-color: #f4f5f7;
    flex-direction: column;

    .primary-heading {
      color: rgb(74, 76, 76);
      font-weight: $title-font-weight;
      font-size: 50px;
      margin-bottom: 10px;
    }

    .secondary-heading {
      color: rgb(180, 181, 187);
      font-size: 15px;
    }
  }

  .content-container {
    padding: 70px 135px;
    margin-top: 0;

    .content-main-row {
      .form-container {
        .ant-input:not(textarea) {
          height: 40px;
        }

        textarea {
          resize: none;
        }

        .send-message-button {
          background-color: rgb(123, 181, 25);
          color: #ffffff;
          font-weight: $title-font-weight;
          height: 40px;
        }

      }

      .contact-detail-container {
        flex-direction: column;
        gap: 15px;

        .contact-detail-item {
          gap: 10px;
          justify-content: start;
          color: $title-text-color;
          font-size: 22px;
          font-weight: $title-font-weight;
        }

        .contact-icon {
          height: 32px;
          width: 32px;
        }
      }
    }

    &.yoga-content {
      padding: 0;
      font-size: 17px;
      color: rgb(139, 144, 145);
      line-height: 30px;

      .content-sub-heading {
        color: rgb(74, 76, 76);
        font-weight: 700;
        font-size: 25px;
      }
    }
  }

  .image-container {
    flex-direction: column;
    justify-content: center;

    img {
      height: 100%;
      width: 100%;
      border-radius: 20px;
    }
  }

  .experience-container {
    padding: 50px 0;

    .content-sub-heading {
      color: rgb(74, 76, 76);
      font-weight: 700;
      font-size: 25px;
      text-align: center;
      padding: 0 0 30px;
    }

    .experience-col {
      display: grid;
      justify-content: center;
      grid-template-columns: .5fr;

      .inner-container {
        display: grid;
        grid-template-columns: 1fr max-content 1fr;
        column-gap: 1.5rem;

        .experience-content {
          padding: 15px 0;
          font-size: 17px;
          color: rgb(139, 144, 145);
          line-height: 30px;

          &.center-align-text {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .exp-image {
            border-radius: 50%;
            width: 150px;
            height: 150px;
          }
        }

        .middle-container {
          position: relative;
          width: 13px;

          .round {
            width: 13px;
            height: 13px;
            background-color: rgb(117, 117, 117);
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
          }

          .v-line {
            display: block;
            width: 2px;
            height: 100%;
            background-color: rgb(117, 117, 117);
            transform: translate(-50%, 0);
            position: absolute;
            left: 50%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .yoga-page-container {
    .content-container {
      padding: 70px 50px;
    }
  }
}

@media screen and (max-width: 768px) {
  .contact-us-page-container,
  .yoga-page-container {
    .headings-container {
      .primary-heading {
        font-size: 30px;
      }
    }

    .experience-container {
      .experience-col {
        grid-template-columns: .9fr;
      }
    }

    .content-container {
      padding: 70px 20px;
    }
  }
}

@media screen and (max-width: 576px) {
  .yoga-page-container {
    .experience-container {
      .experience-col {
        grid-template-columns: 1fr;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .yoga-page-container {
    .experience-container {
      .experience-col {
        .inner-container {
          column-gap: .2rem;
        }
      }
    }
  }
}