.our-programs-main-container {
  padding: 120px 0;

  .heading-row {
    margin-bottom: 60px;

    .primary-heading {
      position: relative;
      margin-bottom: 5px;

      .backdrop-line {
        border: none;
        border-top: 3px solid $color-primary;
        width: 275px;
      }

      .heading-text {
        position: absolute;
        top: -60%;
        background-color: #ffffff;
        padding: 0 5px;
        font-style: italic;
        font-size: $heading-font-size;
        color: $color-primary;
        font-weight: $title-font-weight;
      }
    }

    .secondary-heading {
      font-size: 50px;
      font-weight: $title-font-weight;
      color: #7bb519;
    }
  }

  .swiper-container {
    .programs-container {
      height: 380px;
      width: 1100px;
    }

    .swiper-pagination-bullet-active {
      background-color: #7bb519;
    }
  }
}

.program-card-main-container {
  padding: 30px 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 30px;

  .content-row {
    .program-image {
      height: 120px;
      width: 120px;
      border-radius: 50%;
      margin-bottom: 20px;
    }

    .primary-heading {
      line-height: 34px;
      font-size: 26px;
      font-weight: $title-font-weight;
      color: $title-text-color;
      margin-bottom: 5px;
      text-align: center;
    }

    .secondary-heading {
      font-style: italic;
      font-weight: normal;
      color: #b4b5bb;
      margin-bottom: 20px;
      font-size: 20px;
    }

    .know-more-button {
      background-color: #7bb519;
      color: #ffffff;
      font-weight: $title-font-weight;
      height: 35px;
      width: 166px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .our-programs-main-container {
    .swiper-container {
      .programs-container {
        width: 850px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .our-programs-main-container {
    .swiper-container {
      .programs-container {
        width: 90%;
      }
    }
  }

  .program-card-main-container {
    .content-row {
      .primary-heading {
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .our-programs-main-container {
    .heading-row {
      .secondary-heading {
        font-size: 40px;
      }
    }
  }

  .program-card-main-container {
    .content-row {
      .know-more-button {
        height: 40px;
      }

      .primary-heading {
        font-size: 26px;
      }
    }
  }
}