.order-program-main-container,
.contact-us-page-container {

  .heading-row {
    margin-bottom: 60px;

    .primary-heading {
      position: relative;
      margin-bottom: 5px;

      .backdrop-line {
        border: none;
        border-top: 3px solid $color-primary;
        width: 225px;
      }

      .heading-text {
        position: absolute;
        top: -60%;
        background-color: #ffffff;
        padding: 0 5px;
        font-style: italic;
        font-size: $heading-font-size;
        color: $color-primary;
        font-weight: $title-font-weight;
      }
    }

    .secondary-heading {
      font-size: 50px;
      font-weight: $title-font-weight;
      color: #7bb519;
    }
  }

  .form-container {
    margin-left: 121px;

    .ant-input:not(textarea) {
      height: 40px;
    }

    textarea {
      resize: none;
    }

    .program-label {
      margin-bottom: 10px;
    }

    .options-container {
      margin-bottom: 24px;
    }

    .place-order-button {
      background-color: rgb(123, 181, 25);
      font-weight: 700;
      color: #ffffff;
      height: 40px;
      width: 126px;
    }

    .form-label {
      font-size: 17px;
      color: $title-text-color;

      label {
        font-size: 17px;
        color: $title-text-color;
      }
    }

    .program-item {
      padding: 4px 12px;
      border: 1px solid rgb(123, 181, 25);
      border-radius: 6px;
      color: #7bb519;
      font-weight: 500;

      &.selected {
        background-color: rgb(123, 181, 25);
        color: #ffffff;
      }
    }
  }

  .image-container-col {
    padding: 0 300px;
  }

  .image-container {
    background-image: url("../Assests/order-program-01.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    //width: 562px;
    width: 100%;
    height: 672px;
    position: relative;
    border-radius: 20px;

    .inner-quote {
      font-weight: bold;
      color: #7bb519;
      font-size: 40px;
      position: absolute;
      top: 14%;
      left: 50%;
      transform: translate(-50%, 0);
      text-align: center;
      white-space: nowrap;
    }

    .order-program-button {
      background-color: #7bb519;
      color: #ffffff;
      border-color: #7bb519;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .msg-1 {
      color: #7bb519;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%, 0);
      white-space: nowrap;
    }

    .msg-2 {
      color: #b4b5bb;
      position: absolute;
      left: 50%;
      top: 45%;
      transform: translate(-50%, 0);
      white-space: nowrap;
    }
  }
}

@media screen and (max-width: 1024px) {
  .order-program-main-container,
  .contact-us-page-container{
    .image-container-col {
      padding: 0 150px;
    }
    .image-container {
      //height: 600px;

      .inner-quote {
        top: 10%;
        white-space: normal;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .order-program-main-container,
  .contact-us-page-container{
    .ant-row:has(.form-container) {
      margin: 0 !important;
    }

    .form-container {
      margin-left: 0;
    }

    .image-container-col {
      padding: 0 70px;
    }

    .image-container {
      //width: 50%;
      //height: 400px;
      display: flex;
      justify-self: center;

      .inner-quote {
        width: 80%;
        top: 10%;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .order-program-main-container,
  .contact-us-page-container{
    .heading-row {
      .secondary-heading {
        font-size: 40px;
      }
    }
    .image-container-col {
      padding: 0 20px;
    }
    .image-container {
      //width: 90%;
      //height: 400px;
      display: flex;
      justify-self: center;

      .inner-quote {
        width: 80%;
        top: 5%;
      }

      .msg-1 {
        top: 32%;
        white-space: normal;
        width: 80%;
        text-align: center;
      }

      .order-program-button {
        top: 43%;
      }

      .msg-2 {
        top: 50%;
        white-space: normal;
        width: 80%;
        text-align: center;
      }
    }
  }
}