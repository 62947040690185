.program-detail-main-container {
  .headings-container {
    height: 400px;
    background-color: #f4f5f7;
    flex-direction: column;
    background-size: cover;
    background-position: center;
    backdrop-filter: blur(10px);

    .blurred-filter {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(10px);
      z-index: 25;
    }

    .headings-inner-container {
      padding: 20px;
      background-color: #ffffff;
      border-radius: 20px;

      .primary-heading {
        z-index: 50;
        color: rgb(74, 76, 76);
        //color: white;
        font-weight: $title-font-weight;
        font-size: 50px;
        margin-bottom: 10px;

        &.white-text-color {
          color: white;
        }
      }

      .secondary-heading {
        text-align: center;
        z-index: 50;
        //color: rgb(180, 181, 187);
        color: rgb(74, 76, 76);
        font-size: 15px;

        &.white-text-color {
          color: white;
        }
      }
    }
  }

  .content-container {
    margin-top: 0;

    .content-row {
      display: flex;

      .image-container {
        height: 1369px;
        width: 735px;
        background-repeat: no-repeat;
        background-position: center;
        background-attachment: fixed;
        background-size: cover;
      }

      .info-container {
        font-size: 17px;
        color: rgb(139, 144, 145);
        line-height: 30px;
        padding: 40px 200px 0;

        .mb-75 {
          margin-bottom: 75px;
        }

        .p-top-btm-10 {
          padding: 10px 0;
        }

        .horizontal-line {
          border-top: 1px solid rgba(51, 51, 51, 0.12);
        }

        .fw-700 {
          font-weight: 700;
        }

        a {
          color: #7bb519;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .program-detail-main-container {
    .headings-container {
      .headings-inner-container {
        .primary-heading {
          text-align: center;
          font-size: 40px;
        }
      }
    }
    .content-container {
      .content-row {
        .info-container {
          padding: 40px 100px 0;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .program-detail-main-container {
    .headings-container {

      .headings-inner-container {
        padding: 10px;
        .primary-heading {
          font-size: 35px;
          text-align: center;
          margin-bottom: 10px;
        }

        .secondary-heading {
          font-size: 15px;
        }
      }
    }

    .content-container {
      .content-row {
        .info-container {
          padding: 40px 20px 0;
        }
      }
    }
  }
}