.about-section-main-container {
  padding: 70px 110px;

  .tenzin-img {
    object-fit: cover;
    object-position: center;
    height: 450px;
    width: 450px;
    border-radius: 50%;
  }

  .details-container {
    .details-inner-row {
      .about-name {
        color: $title-text-color;
        font-size: 30px;
        font-weight: $title-font-weight;
        margin-bottom: 15px;
      }

      .about-designation {
        font-style: italic;
        color: #7bb519;
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 30px;
      }

      .about-description {
        color: rgb(139, 144, 145);
        line-height: 30px;
        font-size: $text-font-size;
        margin-bottom: 65px;
      }

      .buttons-container {
        gap: 10px;

        .about-section-button {
          color: rgb(123, 181, 25);
          border: 2px solid rgb(123, 181, 25);
          width: 196px;
          height: 35px;
          font-weight: bold;
          gap: 3px;

          &.filled {
            background-color: rgb(123, 181, 25);
            color: #ffffff;
            gap: 8px;
          }

          .action-button-icon {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .about-section-main-container {
    .img-container {
      .tenzin-img {
        width: 362px;
        height: 362px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .about-section-main-container {
    padding: 105px 45px;

    .img-container {
      display: flex;
      justify-content: center;
      padding-bottom: 65px;
    }

    .details-container {
      .details-inner-row {
        .buttons-container {
          .about-section-button {
            height: 40px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .about-section-main-container {
    padding: 105px 20px;

    .img-container {
      padding-bottom: 15px;

      .tenzin-img {
        width: 274px;
        height: 274px;
      }
    }

    .details-container {
      padding-top: 30px;

      .details-inner-row {
        .buttons-container {
          .about-section-button:nth-last-child(2), .about-section-button:nth-last-child(1) {
            display: none;
          }
        }
      }
    }
  }
}