.gallery-page-main-container {
  padding: 0 0 80px;

  .headings-container {
    height: 250px;
    background-color: #f4f5f7;
    flex-direction: column;

    .primary-heading {
      color: rgb(74, 76, 76);
      font-weight: $title-font-weight;
      font-size: 50px;
      margin-bottom: 10px;
    }

    .secondary-heading {
      color: rgb(180, 181, 187);
      font-size: 15px;
    }
  }

  .image-section-wrapper {
    padding-top: 70px;
    justify-content: center;
    width: 100%;

    .gallery-item {
      height: 100%;
      width: 100%;

      .images-container {
        height: 100%;
        width: 20rem;
        border-radius: 20px;

        .carousel-image {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 20px;
        }
      }

      &.gallery-item-1 {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
      }

      &.gallery-item-2 {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
      }

      &.gallery-item-3 {
        grid-column: 2 / span 1;
        grid-row: 1 / span 2;
      }

      &.gallery-item-4 {
        grid-column: 3 / span 1;
        grid-row: 1 / span 3;
      }

      &.gallery-item-5 {
        grid-column: 1 / span 1;
        grid-row: 3 / span 2;
      }

      &.gallery-item-6 {
        grid-column: 3 / span 1;
        grid-row: 4 / span 1;
      }

      &.gallery-item-7 {
        grid-column: 2 / span 1;
        grid-row: 3 / span 2;
      }
    }

    .gallery {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 8rem);
      gap: .5rem;
      width: 60rem;
    }

    .gallery-mobile {
      display: none;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 6rem);
      gap: .5rem;
      width: 30rem;
      padding: 0 20px;

      .gallery-item {
        .images-container {
          width: calc(100vw / 2 - 40px);
        }

        &.gallery-item-1 {
          grid-column: 1 / span 1;
          grid-row: 1 / span 2;
        }

        &.gallery-item-2 {
          grid-column: 1 / span 1;
          grid-row: 3 / span 2;
        }

        &.gallery-item-4 {
          grid-column: 2 / span 1;
          grid-row: 1 / span 3;
        }

        &.gallery-item-3 {
          grid-column: 2 / span 1;
          grid-row: 4 / span 1;
        }
      }
    }
  }

  .video-section-wrapper {
    //width: 60rem;
    justify-content: center;
    padding: 80px 0;

    .arrow-container {
      position: relative;
    }

    .inner-container {
      overflow-x: hidden;
      overflow-y: hidden;
      max-width: 60rem;
      gap: 2rem;

      .video-item {
        //border: 2px solid red;
        width: 20rem;
        height: 371px;
        flex-shrink: 0;

        .video-content {
          height: 100%;
          width: 100%;
          border-radius: 20px;
        }
      }
    }

    .inner-container::-webkit-scrollbar {
      display: none;
    }

    .left-arrow {
      height: 3rem;
      width: 3rem;
      //background-color: white;
      position: absolute;
      left: -60px;
      top: 40%;
      transform: rotate(180deg);
    }

    .right-arrow {
      height: 3rem;
      width: 3rem;
      //background-color: white;
      position: absolute;
      right: -60px;
      top: 40%;
    }
  }
}

.images-carousel-modal {
  top: 20px;

  .ant-modal-body {
    position: relative;
  }

  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.85);
  }

  .swiper-wrapper {
    display: flex;
    align-items: center;
  }

  .ant-modal-content {
    background-color: transparent !important;
    box-shadow: none !important;

    .images-container {
      //height: 100;
      //width: 20rem;
      max-height: 40rem;
      border-radius: 20px;

      .carousel-image {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 20px;
      }
    }

    .left-arrow {
      height: 2.2rem;
      width: 2.2rem;
      //background-color: white;
      position: absolute;
      left: -50px;
      top: 55%;
      transform: rotate(180deg);
      transform-origin: 50% 50%;
    }

    .right-arrow {
      height: 2.2rem;
      width: 2.2rem;
      //background-color: white;
      position: absolute;
      right: -50px;
      top: 55%;
    }

    .close-icon {
      position: absolute;
      height: 2.2rem;
      width: 2.2rem;
      top: -5%;
      right: -50px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .gallery-page-main-container {
    .image-section-wrapper {
      .gallery {
        grid-template-rows: repeat(4, 7rem);
        width: 50rem;

        .gallery-item {
          .images-container {
            width: 16rem;
          }
        }
      }
    }

    .video-section-wrapper {
      .inner-container {
        max-width: 40rem;

        .video-item {
          width: 12rem;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .gallery-page-main-container {
    margin-top: 64px;

    .headings-container {
      .primary-heading {
        font-size: 40px;
      }
    }

    .image-section-wrapper {
      .gallery {
        grid-template-rows: repeat(4, 6.5rem);
        width: 40rem;

        .gallery-item {
          .images-container {
            width: 13rem;
          }
        }
      }
    }

    .video-section-wrapper {
      .inner-container {
        max-width: 35rem;

        .video-item {
          width: 12rem;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .gallery-page-main-container {
    margin-top: 64px;

    .image-section-wrapper {
      .gallery {
        display: none;
      }

      .gallery-mobile {
        display: grid;
      }
    }

    .video-section-wrapper {
      .inner-container {
        max-width: 15rem;
        gap: 0;

        .video-item {
          width: 15rem;
        }
      }

      .left-arrow {
        height: 2rem;
        width: 2rem;
        left: -35px;
        top: 50%;
      }

      .right-arrow {
        height: 2rem;
        width: 2rem;
        right: -35px;
        top: 50%;
      }
    }
  }

  .images-carousel-modal {
    width: calc(100vw * 0.8) !important;

    .ant-modal-content {
      .left-arrow {
        height: 1.5rem;
        width: 1.5rem;
        left: -40px;
        top: 45%;
      }

      .right-arrow {
        height: 1.5rem;
        width: 1.5rem;
        right: -40px;
        top: 45%;
      }

      .close-icon {
        height: 1.5rem;
        width: 1.5rem;
        top: 0;
        right: -40px;
      }
    }
  }
}