.header-main-container {
  height: 90px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 50;
  transition: height .5s ease-in-out;

  &.scrolled {
    height: 64px;
  }

  .items-container {
    .logo-container {
      height: 100%;

      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }

    .header-item {
      font-size: $text-font-size;
      color: $title-text-color;
      font-weight: $title-font-weight;
    }

    .header-item:hover {
      color: $color-primary;
      border-bottom: 2px solid $color-primary;
      padding-bottom: 2px;
    }
  }

  .social-icons-container {
    .icon-container {
      border: 1px solid $color-primary;
      border-radius: 5px;
      height: 30px;
      width: 30px;

      .social-media-icon {
        height: 18px;
        width: 18px;
      }
    }

    .icon-container:hover {
      background-color: $color-primary;

      svg {
        path {
          fill: #ffffff;
        }
      }

      .social-media-icon {
      }
    }
  }
}

.header-main-container-mobile {
  background-color: #ffffff;
  display: none;
  padding: .5rem 20px;
  height: 64px;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;


  .logo-container {
    height: 100%;

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }

  .menu-icon {
    height: 39px;
    width: 46px;
  }
}

.mobile-expanded-header {
  background-color: #ffffff !important;

  .header-item {
    color: $title-text-color;
    font-weight: $title-font-weight;
    font-size: 18px;

    &.selected {
      color: $color-primary;
    }

    .plus-icon {
      height: 18px;
      width: 26px;
      margin-top: .25rem;
      margin-left: auto;
    }
  }

  .header-item:active {
    color: $color-primary;
  }

  .programmes-list {
    height: 350px;
    transition: height .5s linear;
    overflow-y: hidden;

    .list-item {
      font-size: 17px;
      padding: 6px 0;
      color: $title-text-color;
    }

    .list-item:active {
      color: $color-primary;
    }

    &.compressed {
      height: 0;
    }
  }

  .close-button {
    color: #fff;
    height: 36px;
    width: 36px;
  }

  .social-icons-container {
    margin-top: 40px;

    .icon-container {
      border: 1px solid $color-primary;
      height: 30px;
      width: 30px;
      border-radius: 5px;

      .social-media-icon {
        height: 18px;
        width: 18px;
      }
    }

    .icon-container:hover {
      background-color: $color-primary;

      svg {
        path {
          fill: #ffffff;
        }
      }

      .social-media-icon {
      }
    }
  }
}

.header-dropdown-main-container {
  .ant-dropdown-menu-title-content {
    padding: 5px;
    font-size: 17px;
  }

  .ant-dropdown-menu-item-active {
    background-color: rgba(123, 181, 25, 0.1) !important;
  }
}

@media screen and (max-width: 786px) {
  .header-main-container {
    display: none;
  }

  .header-main-container-mobile {
    display: flex;

    .logo-container {
      img {
        height: 6rem;
        width: 6rem;
        position: absolute;
        top: -1.5rem;
        left: -1rem;
      }
    }
  }
}