.leave-reply-main-container {
  padding-top: 150px;

  .headings-container {
    .primary-heading {
      font-size: 30px;
      font-weight: $title-font-weight;
      color: $title-text-color;
      text-align: center;
      margin-bottom: 40px;

      .horizontal-line {
        width: 60px;
        border: none;
        border-top: 4px solid rgb(123, 181, 25);
      }
    }

    .secondary-heading {
      text-align: center;
      color: rgb(139, 144, 145);
      font-size: 13px;
    }
  }

  .form-container {
    padding: 15px 120px 30px 120px;

    .ant-input:not(textarea) {
      height: 40px;
    }

    textarea {
      resize: none;
    }

    .post-comment-button {
      height: 40px;
      background-color: rgb(123, 181, 25);
      color: #ffffff;
      font-weight: $title-font-weight;
    }
  }
}


@media screen and (max-width: 768px) {
  .leave-reply-main-container {
    .form-container {
      padding: 15px 20px 30px 20px;
    }
  }
}

@media screen and (max-width: 576px) {
  .leave-reply-main-container {
    .headings-container {
      .secondary-heading {
        line-height: 26px;
        padding: 0 20px;
      }
    }
  }
}