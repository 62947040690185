.home-page-main-container {
  .intro-container {
    background-image: url("../Assests/bg-01.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: calc(100vh - 90px);

    .content-main-row {
      z-index: 50;

      .headings-container {
        padding-left: 130px;
        padding-top: 70px;
        flex-direction: column;
        padding-right: 42px;

        div {
          text-align: center;
        }
      }

      .first-quote {
        font-weight: $title-font-weight;
        font-size: 26px;
        color: rgb(123, 181, 25);
        font-family: 'Josefin Sans';
        font-style: italic;
        margin-bottom: 20px;
        line-height: 36px;
      }

      .second-quote {
        font-weight: $title-font-weight;
        color: $title-text-color;
        font-size: 70px;
        line-height: 76px;
        margin-bottom: 15px;
      }

      .third-quote {
        color: $text-color;
        font-weight: $text-font-weight;
        font-size: 24px;
        line-height: 38px;
        margin-bottom: 35px;

        .owner-name {
          color: rgb(123, 181, 25);
          font-weight: $title-font-weight;
        }
      }

      .about-Tenzin-button {
        background-color: rgb(123, 181, 25);
        color: #ffffff;
        font-size: 17px;
        font-weight: $title-font-weight;
        height: 45px;
        width: 197px;

        .graduate-icon {
          height: 18px;
          width: 18px;
          margin-left: .5rem;
        }
      }

      .carousel-container {
        padding-top: 70px;

        .images-container {
          height: 450px;
          width: 450px;
          border-radius: 20px;
          //display: flex;

          .carousel-image {
            width: 100%;
            height: 450px; /* Set the desired height of the carousel images */
            object-fit: cover;
          }
        }

        .swiper-pagination-bullet-active {
          background-color: #ffffff;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .home-page-main-container {
    .intro-container {
      height: auto;

      .content-main-row {
        padding-bottom: 50px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .home-page-main-container {
    .intro-container {

      .content-main-row {

        .headings-container {
          padding-left: 92px;
          padding-top: 140px;
          padding-right: 92px;
        }

        .first-quote {
          font-size: 24px;
          line-height: 34px;
        }

        .second-quote {
          font-size: 50px;
          line-height: 56px;
        }

        .third-quote {
          font-size: 20px;
          line-height: 34px;
        }

        .carousel-container {
          .images-container {
            height: 361px;
            width: 361px;

            .carousel-image {
              height: 361px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .home-page-main-container {
    .intro-container {

      .content-main-row {

        .headings-container {
          padding-left: 51px;
          padding-top: 90px;
          padding-right: 51px;
        }

        .first-quote {
          font-size: 20px;
          line-height: 30px;
        }

        .second-quote {
          font-size: 40px;
          line-height: 46px;
        }

        .carousel-container {
          .images-container {
            height: 225px;
            width: 225px;

            .carousel-image {
              height: 225px;
            }
          }
        }
      }
    }
  }
}