.App-main-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .content-container {
    margin-top: 90px;
    flex: 1;
  }

  .scroll-to-top-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: rgba(0, 0, 0, 0.2);
    width: 40px;
    height: 40px;
    border-radius: 10px;

    .arrow-icon {
      width: 20px;
      height: 20px;
    }
  }

  .scroll-to-top-container:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

@media screen and (max-width: 768px){
    .App-main-container {
      .content-container {
        margin-top: 0px;
      }
    }
}