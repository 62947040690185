.what-we-do-main-container {
  padding: 105px 121px;
  background-color: rgb(244, 245, 247);

  .heading-row {
    margin-bottom: 60px;

    .first-heading {
      font-size: 22px;
      font-weight: $title-font-weight;
      color: rgb(165, 210, 105);
    }

    .primary-heading {
      font-size: 50px;
      color: #7bb519;
      font-weight: $title-font-weight;
      margin-bottom: 30px;
    }
  }

  .content-row {
    .content-points {
      li {
        font-size: $text-font-size;
        color: $text-color;
        line-height: 30px;
        padding: 10px 0;
      }

      hr {
        opacity: .3;
      }
    }

    .image-container {
      height: 430px;
      width: 360px;
      background-image: url("../Assests/what-we-do-01.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 80px 30px;
      text-align: center;
      border-radius: 20px;

      .primary-text {
        font-weight: bold;
        color: #7bb519;
        margin-bottom: 15px;
        line-height: 36px;
        font-size: 30px;
        margin-top: 20px;
      }

      .secondary-text {
        font-style: italic;
        color: #ffffff;
        margin-bottom: 20px;
        font-size: 22px;
        line-height: 32px;
      }

      .testimonial-button {
        width: 196px;
        height: 35px;
        background-color: #7bb519;
        color: #ffffff;
        gap: 4px;
        border-color: #7bb519;
        font-weight: $title-font-weight;

        .user-icon {
          height: 16px;
          width: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .what-we-do-main-container {
    padding: 70px 20px;

    .content-row {
      .content-points {
        max-width: 60%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .what-we-do-main-container {
    padding: 105px 20px;

    .content-row {
      .content-points {
        max-width: 100%;
      }
      .ant-col:has(.image-container) {
        display: flex;
        justify-content: center;
      }
    }
  }
}


@media screen and (max-width: 576px) {
  .what-we-do-main-container {

    .heading-row {
      .first-heading {
        margin-bottom: 5px;
      }

      .primary-heading {
        font-size: 40px;
        line-height: 46px;
        text-align: center;
      }
    }

    .content-row {
      .content-points {
        ul{
          padding-inline-start: 20px;
        }
        li {
          padding: 3px 0;
        }
      }

      .image-container {
        height: fit-content;
        width: 100%;

        .testimonial-button {
          height: 40px;
        }
      }
    }
  }
}