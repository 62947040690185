.stats-container {
  height: 206px;
  background-image: url("../Assests/bg-02.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  color: #ffffff;

  .stats-data-row {
    height: 100%;
    width: 100%;
    margin-left: 0 !important;

    .stats-col {
      gap: 15px;
      justify-content: center;

      .icon-container {
        width: 72px;
        height: 72px;
        border-radius: 50%;
        background-color: #7bb519;

        .stats-icon {
          width: 30px;
          height: 30px;
        }
      }

      .data-container {
        display: flex;
        flex-direction: column;
        margin-top: -5px;

        .data-num {
          font-weight: $title-font-weight;
          font-size: 38px;

          .plus-sign {
            font-size: 30px;
          }
        }

        .data-desc {
          font-size: 20px;
          font-style: italic;
        }
      }

    }
  }
}

@media screen and (max-width: 768px) {
  .stats-container {
    height: fit-content;
    padding: 85px 0;

    .stats-data-row {
      .stats-col {
        padding: 0 75px !important;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .stats-container {

    .stats-data-row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .stats-col {
        padding: 0 50px !important;
      }
    }
  }
}