.review-section-main-container {
  background-image: url("../Assests/bg-03.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  padding: 50px 50px 70px;
  margin-top: 120px;

  .heading-row {
    .secondary-heading {
      font-size: 40px;
      font-weight: $title-font-weight;
      color: #ffffff;
      padding-bottom: 40px;
    }
  }

  .outer-container {
    position: relative;

    .inner-container {
      width: 100%;
      overflow-x: auto;
      display: flex;
      gap: 1rem;

      .item-container {
        //height: 2rem;
        width: 15rem;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 30px;
        background-color: rgba(244, 245, 247, 0.2);
        padding: 20px 0px;


        .image-container {
          height: 90px;
          width: 90px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .reviewer-name {
          color: #ffffff;
          font-size: 22px;
          line-height: 30px;
          font-weight: bold;
        }

        .catch-phrase-container {
          font-size: 17px;
          line-height: 28px;
          font-weight: bold;
          color: #7bb519;
          text-align: center;
        }

        .know-more-button {
          font-size: 17px;
          font-weight: bold;
          align-items: center;
        }
      }
    }

    .blurred-edge {
      position: absolute;
      top: 5%;
      right: 1rem;
      height: 90%;
      width: 0;
      //backdrop-filter: blur(10px); /* Adjust the blur radius as needed */
      pointer-events: none; /* Allow scrolling through the blurred layer */
      //background: linear-gradient(90deg, hsla(0, 0%, 100%, .3), #fff);
      opacity: 1;
      box-shadow: 20px 0 22px 15px gray;

      &.left {
        left: -1rem;
      }
    }

    .blurred-side::before,
    .blurred-side::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 20px; /* Adjust the width of blurred edges as needed */
      background: inherit;
      backdrop-filter: inherit;
    }

    .blurred-side::before {
      left: 0;
    }

    .blurred-side::after {
      right: 0;
    }
  }

  .inner-container::-webkit-scrollbar {
    display: none;
  }
}

.review-modal-container {
  .modal-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .images-container {
      img {
        height: 160px;
        width: 160px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .name-container {
      font-size: 1.5rem;
      font-weight: 700;
    }

    .description-container {
      color: #8b9091;
    }

    .description-image-container {
      img {
        border-radius: 20px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .review-section-main-container {
    .heading-row {
      .secondary-heading {
        font-size: 30px;
      }
    }

    .outer-container {
      .inner-container {
        .item-container {
          width: 13rem;
          gap: .5rem;

          .reviewer-name {
            font-size: 18px;
          }

          .catch-phrase-container {
            font-size: 13px;
          }

          .know-more-button {
            font-size: 13px;
          }
        }
      }

      .blurred-edge {
        //width: 1.5rem;
        right: 0.5rem;

        &.left {
          left: -0.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .review-section-main-container {
    padding: 50px 10px;

    .heading-row {
      .secondary-heading {
        font-size: 25px;
      }
    }

    .outer-container {
      .inner-container {
        .item-container {
          width: 12rem;
        }
      }
    }
  }

  .review-modal-container {
    .modal-content-container {
      .description-image-container {
        max-width: 400px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .review-section-main-container {
    .heading-row {
      .secondary-heading {
        font-size: 20px;
      }
    }
  }

  .review-modal-container {
    .modal-content-container {
      .description-image-container {
        max-width: 300px;
      }
    }
  }
}