.footer-main-container {
  color: #ffffff;
  background-color: #272929;
  padding: 20px 70px;
  margin-top: auto;

  .inner-content-row {
    .social-icons-container {
      .icon-container {
        border: 1px solid #ffffff;
        border-radius: 5px;
        height: 30px;
        width: 30px;

        .social-media-icon {
          height: 18px;
          width: 18px;
        }
      }

      .icon-container:hover {
        background-color: #ffffff;

        svg {
          path {
            fill: #272929;
          }
        }

        .social-media-icon {
        }
      }
    }

    .developer-website {
      justify-content: end;
    }
  }
}

@media screen and (max-width: 768px){
  .footer-main-container {
    .inner-content-row {
      .developer-website {
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .footer-main-container {
    padding: 20px 30px;

    .inner-content-row {
      .rights-reserved {
        text-align: center;
      }
      .developer-website {
        //justify-content: flex-start;
      }
    }
  }
}